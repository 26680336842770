import { Controller } from "@hotwired/stimulus";

// Connects with data-controller="url"
export default class extends Controller<HTMLElement> {
  static values = {
    params: Array,
  };

  declare paramsValue: Array<{
    key: string;
    value: string;
  }>;

  connect() {
    if (this.paramsValue.length) {
      this.addParams();
    }
  }

  removeParams() {
    const url = new URL(window.location.href);

    this.paramsValue.forEach(({ key }) => {
      url.searchParams.delete(key);
    });
    window.history.pushState({}, "", url);
  }

  addParams() {
    const url = new URL(window.location.href);

    this.paramsValue.forEach(({ key, value }) => {
      url.searchParams.set(key, value);
    });
    window.history.pushState({}, "", url);
  }
}
