import { Controller } from "@hotwired/stimulus";

import queryString from "query-string";

// Connects with data-controller="new-build-dialog-opener"
export default class extends Controller<HTMLAnchorElement> {
  declare hashChangeListener: () => void;

  connect() {
    this.hashChangeListener = () => {
      this.open();
    };

    window.addEventListener("hashchange", this.hashChangeListener);

    this.open();
  }

  disconnect() {
    window.removeEventListener("hashchange", this.hashChangeListener);
  }

  open() {
    const [hashPath, hashQuery] = window.location.hash.split("?");
    const [hrefPath, hrefQuery] = this.element.href.split("?");

    let destination = hrefPath;
    const destinationQuery = queryString.stringify({
      ...queryString.parse(hrefQuery),
      ...queryString.parse(hashQuery),
      ...queryString.parse(window.location.search),
    });

    if (destinationQuery) {
      destination = `${destination}?${destinationQuery}`;
    }

    this.element.href = destination;

    if (hashPath === "#new") {
      this.element.click();
    }
  }
}
