import { Controller } from "@hotwired/stimulus";
import * as Turbo from "@hotwired/turbo";

// Connects with data-controller="polling"
// Use this controller sparingly on low throughput pages
// when there isn't an event to trigger a turbo stream.
// This will refresh a trubo frame every 5 seconds by default.
export default class extends Controller<HTMLDialogElement> {
  static values = {
    timeout: { type: Number, default: 5000 },
    url: String,
  };

  declare urlValue: string;
  declare timeoutValue: number;
  declare timeout: ReturnType<typeof setTimeout>;

  connect() {
    this.timeout = setTimeout(() => {
      this.refresh();
    }, this.timeoutValue);
  }

  disconnect(): void {
    clearTimeout(this.timeout);
  }

  refresh() {
    Turbo.visit(this.urlValue, { frame: this.turboFrame });
  }

  get turboFrame() {
    return this.element.dataset.turboFrame;
  }
}
