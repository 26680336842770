import { Controller } from "@hotwired/stimulus";

// Connects with data-controller="new-composite-registry-form"
export default class extends Controller {
  static values = { ecosystem: String, hints: Object };
  declare ecosystemValue;
  declare hintsValue;

  static targets = ["hint", "officialRegistryPrompt", "officialRegistry"];
  declare hintTarget;
  declare officialRegistryPromptTarget;
  declare officialRegistryTarget;

  static classes = ["hidden"];
  declare hiddenClass;

  setEcosystem(event) {
    this.ecosystemValue = event.target.value;
  }

  ecosystemValueChanged() {
    if (this.ecosystemValue === "") {
      this.officialRegistryPromptTarget.classList.remove(this.hiddenClass);
      this.officialRegistryTarget.classList.add(this.hiddenClass);
    } else {
      this.officialRegistryPromptTarget.classList.add(this.hiddenClass);
      this.officialRegistryTarget.classList.remove(this.hiddenClass);
      this.hintTarget.textContent = this.hintsValue[this.ecosystemValue];
    }
  }
}
