import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    systemState: String,
    userPreference: { type: String, default: "system" },
  };
  declare prefersDarkValue: boolean;
  declare userPreferenceValue: string | undefined;
  declare systemStateValue: string | undefined;

  connect() {
    window
      .matchMedia("(prefers-color-scheme: dark)")
      .addEventListener("change", this.#setSystemState.bind(this));

    this.#setTheme();
    this.#setSystemState();
  }

  disconnect() {
    window
      .matchMedia("(prefers-color-scheme: dark)")
      .removeEventListener("change", this.#setSystemState);
  }

  // value is either dark, light or system from
  // app/views/layouts/_theme_selection.html.erb
  select({ target: { value } }) {
    this.userPreferenceValue = value;
    this.#setSystemState();
    this.#toggleDarkmodeClasses();
  }

  userPreferenceValueChanged() {
    document.cookie = `theme-userPreference=${this.userPreferenceValue};path=/;max-age=31536000`;
  }

  systemStateValueChanged() {
    document.cookie = `theme-systemState=${this.systemStateValue};path=/;max-age=31536000`;
    this.#toggleDarkmodeClasses();
  }

  // Private

  #setSystemState() {
    this.systemStateValue = window.matchMedia("(prefers-color-scheme: dark)")
      .matches
      ? "dark"
      : "light";
  }

  #setTheme() {
    this.userPreferenceValue = getCookie("theme-userPreference") || "system";
  }

  #toggleDarkmodeClasses() {
    if (
      (this.userPreferenceValue === "system" &&
        this.systemStateValue === "dark") ||
      this.userPreferenceValue === "dark"
    ) {
      document.documentElement.classList.add("ui-inverted");
    } else {
      document.documentElement.classList.remove("ui-inverted");
    }
  }
}

function getCookie(name) {
  const cookies = document.cookie ? document.cookie.split("; ") : [];
  const prefix = `${encodeURIComponent(name)}=`;
  const cookie = cookies.find((cookie) => cookie.startsWith(prefix));

  if (cookie) {
    const value = cookie.split("=").slice(1).join("=");
    return value ? decodeURIComponent(value) : undefined;
  }
}

function getSystemPrefersDark() {
  return window.matchMedia("(prefers-color-scheme: dark)").matches;
}
