import { Controller } from "@hotwired/stimulus";

// Connects with data-controller="textarea-pattern-validator"
export default class extends Controller<HTMLAnchorElement> {
  static values = {
    pattern: String,
  };

  declare readonly patternValue: string;

  validate(event) {
    if (this.patternValue && this.patternValue !== "") {
      const regexp = new RegExp(`^(?:${this.patternValue})$`);

      if (!regexp.test(event.target.value)) {
        event.target.setCustomValidity(
          `This field must match the pattern "${this.patternValue}"`,
        );
        event.target.reportValidity();
      } else {
        event.target.setCustomValidity("");
      }
    }
  }
}
