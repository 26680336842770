import { Controller } from "@hotwired/stimulus";

// Connects with data-controller="dialog"
export default class extends Controller<HTMLDialogElement> {
  connect() {
    this.showModal();
  }

  disconnect() {
    this.remove();
  }

  closeOutside(event: { target: HTMLDialogElement }) {
    if (event.target === this.element) {
      this.close();
    }
  }

  close() {
    this.element.close();
    this.remove();
  }

  private showModal() {
    this.element.showModal();
  }

  private remove() {
    this.element.remove();
  }
}
