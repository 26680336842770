import { Controller } from "@hotwired/stimulus";

// Connects with data-controller="revealing-password-field"
export default class extends Controller {
  static targets = ["passwordField"];
  declare passwordFieldTarget: HTMLInputElement;

  toggleReveal() {
    if (this.passwordFieldTarget.type === "text") {
      this.passwordFieldTarget.type = "password";
    } else {
      this.passwordFieldTarget.type = "text";
    }
  }
}
