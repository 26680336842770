import { Controller } from "@hotwired/stimulus";

// Connects with data-controller="drawer"
export default class extends Controller<HTMLElement> {
  close() {
    // @ts-expect-error: TypeScript < 5.2 does not support Popover API
    this.element.hidePopover();
    this.dispatch("close");
  }

  load() {
    // @ts-expect-error: TypeScript < 5.2 does not support Popover API
    this.element.showPopover();
  }

  turboFrameError(event) {
    const { target } = event;
    event.preventDefault();
    target.innerHTML = `<div class="text-center p-5"><div class="h2 bold mb-2">There was an error loading the page.</div><div>Try refreshing the page; if the problem persists <a href="mailto:support@buildkite.com">let us know.</a></div></div></div>`;
    this.load();
  }
}
