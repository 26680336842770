import { Controller } from "@hotwired/stimulus";
import debounce from "lodash/debounce";

export default class extends Controller<HTMLFormElement> {
  declare debouncedSubmit: () => void;

  initialize() {
    this.debouncedSubmit = debounce(this.submit, 400);
  }

  submit() {
    this.element.requestSubmit();
  }
}
