import { Controller } from "@hotwired/stimulus";

// Connects with data-controller="secrets"
export default class extends Controller {
  // our targets define the html elements we want to capture here
  // these are *magically* associated based on the data attributes
  // that are set in source
  static targets = ["form", "textarea", "hidden"];

  declare encodeSubmit: () => void;
  declare formTarget: HTMLFormElement;
  declare textareaTarget: HTMLTextAreaElement;
  declare hiddenTarget: HTMLInputElement;

  initialize() {
    this.encodeSubmit = this.submit;
  }

  submit() {
    // encode the value of the textarea to base64 to preserve line endings
    // and place it into the hidden field
    this.hiddenTarget.value = btoa(this.textareaTarget.value);
    this.formTarget.requestSubmit();
  }
}
