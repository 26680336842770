import { Controller } from "@hotwired/stimulus";

export default class extends Controller<HTMLElement> {
  static targets = ["inclusion", "inclusionCheckbox"];

  declare readonly inclusionTarget: HTMLInputElement;
  declare readonly inclusionCheckboxTarget: HTMLInputElement;

  format() {
    const formattedValue = this.#formatNumber(this.inclusionTarget.value);

    this.inclusionTarget.value = formattedValue;
    this.inclusionTarget.dataset.currentInclusion = formattedValue;
  }

  toggleUnlimited() {
    if (this.inclusionCheckboxTarget.checked) {
      this.inclusionTarget.value = "Infinity";
    } else {
      this.inclusionTarget.value = this.#formatNumber(
        this.inclusionTarget.dataset.currentInclusion || "",
      );
    }

    if (this.inclusionTarget.form) {
      this.inclusionTarget.form.dispatchEvent(new Event("input"));
    }
  }

  #formatNumber(value: string) {
    const numericValue = value.replace(/[^0-9.]/g, "");
    return Number(numericValue).toLocaleString();
  }
}
