import { Controller } from "@hotwired/stimulus";

// Connects with data-controller="storages-hdd"
export default class extends Controller {
  static targets = ["inSound", "outSound", "innerCircle"];
  declare inSoundTarget: HTMLAudioElement;
  declare outSoundTarget: HTMLAudioElement;
  declare innerCircleTarget: HTMLDivElement;

  static classes = ["innerCircleOn"];
  declare innerCircleOnClasses: Array<string>;

  pressPowerButton() {
    this.playSound(this.inSoundTarget);
  }

  releasePowerButton() {
    this.playSound(this.outSoundTarget);
    this.toggleInnerCircleStyle();
  }

  private playSound(audioElement: HTMLAudioElement) {
    audioElement.currentTime = 0;
    audioElement.play();
  }

  private toggleInnerCircleStyle() {
    this.innerCircleOnClasses.forEach((className) => {
      this.innerCircleTarget.classList.toggle(className);
    });
  }
}
