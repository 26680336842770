import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["content"];

  declare readonly contentTarget: HTMLElement;

  show(event: Event) {
    if (event.target instanceof HTMLElement) {
      event.target.setAttribute("aria-expanded", "true");
    }

    this.contentTarget.hidden = false;
  }

  hide(event: Event) {
    if (event.target instanceof HTMLElement) {
      event.target.setAttribute("aria-expanded", "false");
    }

    this.contentTarget.hidden = true;
  }

  toggle(event: Event) {
    if (this.contentTarget.hidden) {
      this.show(event);
    } else {
      this.hide(event);
    }
  }
}
