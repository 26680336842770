// Initializes a Stimulus application and dynamically loads controllers from the `./controllers` directory.
// Name your controller files [identifier]_controller.ts, where identifier corresponds to each controller’s data-controller identifier in your HTML.
//
// Example:
// If your controller file is named: `hello_controller.ts` then your HTML should include `data-controller="hello"`.

import { Application } from "@hotwired/stimulus";
import { definitionsFromContext } from "@hotwired/stimulus-webpack-helpers";

const application = Application.start();
const context = require.context("./controllers", true, /\.ts$/);
application.load(definitionsFromContext(context));
