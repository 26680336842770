import { Controller } from "@hotwired/stimulus";

// Turbo Stream Button
// Author Sean P. Doyle
// https://github.com/seanpdoyle/turbo_stream_button
// TS Controller taken from:
// https://github.com/seanpdoyle/turbo_stream_button/blob/main/packages/turbo_stream_button/turbo_stream_button_controller.ts
//
// Example
// <button type="button" id="call-to-action"
//         data-controller="turbo-stream-button"
//         data-action="click->turbo-stream-button#evaluate">
//   <span>Click me to insert the template's contents after this button!</span>
//
//   <template data-turbo-stream-button-target="turboStreams">
//     <turbo-stream action="after" target="call-to-action">
//       <template><p>You clicked the button!</p></template>
//     </turbo-stream>
//   </template>
// </button>

// Connects with data-controller="turbo-stream-button"
export default class extends Controller {
  static targets = ["turboStreams"];
  declare readonly turboStreamsTargets: HTMLTemplateElement[];

  evaluate({ target }: Event) {
    if (target instanceof Element) {
      for (const { content } of this.turboStreamsTargets) {
        target.append(content.cloneNode(true));
      }
    }
  }
}
