import { Controller } from "@hotwired/stimulus";

// Connects with data-controller="text-field"
export default class extends Controller<HTMLInputElement> {
  enforceAlphanumericDashesAndUnderscores() {
    this.element.value = this.element.value
      .trim()
      .replace(/[^A-Za-z0-9-_]/g, "");
  }

  enforceLowercaseAlphanumericDotsAndHyphens() {
    this.element.value = this.element.value.trim().replace(/[^a-z0-9-.]/g, "");
  }

  enforceLowercaseAlphanumericAndHyphens() {
    this.element.value = this.element.value.trim().replace(/[^a-z0-9-]/g, "");
  }
}
