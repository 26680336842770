import { Controller } from "@hotwired/stimulus";

import { default as CodeMirror, CodeMirrorOptions } from "./codemirror";

const CODEMIRROR_CONFIG: CodeMirrorOptions = {
  mode: "yaml",
  tabSize: 2,
  indentUnit: 2,
  indentWithTabs: false,
  autoCloseBrackets: true,
  matchBrackets: true,
  showCursorWhenSelecting: true,
};

const TRIGGER_RULE_EXAMPLE = `{
  "rule": "pipeline.trigger_build.pipeline",
  "value": {
    # The pipeline with the trigger step - accepts a pipeline UUID or slug (required)
    "source_pipeline": "pipeline-uuid",
    # The pipeline where the build will be created - accepts a pipeline UUID or slug (required)
    "target_pipeline": "pipeline-uuid",
    # The conditions that need to be met to trigger a build. With no conditions all users
    # can trigger all branches in the target pipeline. (optional)
    # See https://buildkite.com/docs/pipelines/conditionals#variable-and-syntax-reference-variables
    # for full list of available variables.
    "conditions": [
      # Example: Require the triggering build to be on the main branch
      "source.build.branch == 'main'",
      # Example: Require the triggering build to be created by a member of the deploy team
      "source.build.creator.teams includes 'deploy'"
    ]
  }
}`;

const ARTIFACTS_READ_RULE_EXAMPLE = `{
  "rule": "pipeline.artifacts_read.pipeline",
  "value": {
    # The pipeline that reads the artifacts - accepts a pipeline UUID or slug (required)
    "source_pipeline": "pipeline-uuid",
    # The pipeline that contains the artifacts - accepts a pipeline UUID or slug (required)
    "target_pipeline": "pipeline-uuid"
  }
}`;

// Connects with data-controller="rules"
export default class extends Controller {
  static targets = ["editor", "ruleSelect", "section"];
  declare editorTarget: HTMLInputElement;
  declare ruleSelectTarget: HTMLInputElement;
  declare sectionTarget: HTMLInputElement;

  editor: CodeMirror;

  connect() {
    this.editor = CodeMirror.fromTextArea(this.editorTarget, CODEMIRROR_CONFIG);

    this.editor.getWrapperElement().setAttribute("name", "codeMirrorEditor");

    this.ruleSelectTarget.addEventListener("change", (event) => {
      this.updateRuleName(event.target);
    });
  }

  updateRuleName(select) {
    const ruleName = select.value;
    const exampleBody = this.getExampleBodyForRule(ruleName);
    if (this.sectionTarget.classList.contains("hidden")) {
      this.sectionTarget.classList.remove("hidden");
    }

    this.editor.setValue(exampleBody);
  }

  getExampleBodyForRule(ruleName) {
    switch (ruleName) {
      case "pipeline.trigger_build.pipeline":
        return TRIGGER_RULE_EXAMPLE;
      case "pipeline.artifacts_read.pipeline":
        return ARTIFACTS_READ_RULE_EXAMPLE;
      default:
        return {};
    }
  }
}
