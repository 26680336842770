import { ActionEvent, Controller } from "@hotwired/stimulus";
import { Turbo } from "@hotwired/turbo-rails";

export default class extends Controller {
  static values = {
    location: String,
  };

  declare readonly locationValue: string;

  navigate(event: ActionEvent) {
    Turbo.visit(event.params.location ?? this.locationValue);
  }
}
